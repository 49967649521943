<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div v-if="hasBlock && hasBlock.isActive" class="page-message" :style="{'background-color': hasBlock.colour}">
            <span>
              {{ hasBlock.message }}
            </span>
          </div>
          <div class="page-title-inner">
            <router-link v-if="showBtn" to="/enterprise/new-order" class="button button--fill button--fly">
              <i class="ri-map-pin-add-fill"></i>
              Book Order
            </router-link>
            <div class="orders__nav-list">
              <div class="orders__nav-list-part">
                <div
                  class="orders__nav-item"
                  :class="{'orders__nav-item--active': status === null}"
                  @click="filterOrders(null)"
                >
                  Recent
                </div>
                <div
                  class="orders__nav-item"
                  :class="{'orders__nav-item--active': status === 0}"
                  @click="filterOrders(0)"
                >
                  Pending
                </div>
                <div
                  class="orders__nav-item"
                  :class="{'orders__nav-item--active': status === 3}"
                  @click="filterOrders(3)"
                >
                  Declined
                </div>
                <div
                  class="orders__nav-item"
                  :class="{'orders__nav-item--active': status === 2}"
                  @click="filterOrders(2)"
                >
                  Paid
                </div>
              </div>
              <div class="orders__nav-list-part">
                <date-picker
                  v-model="dateRange"
                  range
                  format="DD-MM-YYYY"
                  value-type="format"
                  style="width: 220px"
                  :disabled="status !== null"
                  placeholder="Select date range"
                  @change="filterOrdersDate"
                ></date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-main">
        <div class="container">
          <Order v-for="order of orders" :key="order.id" :order="order" @click.native="openOrder(order)" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Order from '../Order'
import DatePicker from 'vue2-datepicker'
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'ClientDeliveries',
  components: {Order, InfiniteLoading, DatePicker},
  data() {
    return {
      isLoading: false,
      status: null,
      dateRange: null,
      moment
    }
  },
  computed: {
    ...mapState({
      hasBlock: s => s.operationStatus.hasBlock
    }),
    showBtn() {
      if (!this.hasBlock || this.hasBlock?.bypassed) {
        return true
      } else {
        return false
      }
    },
    orders() {
      return this.$store.getters['orders/orders']
    },
    hasMoreResults() {
      return this.orders.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['orders/pagination'].total
    },
    page() {
      return this.$store.getters['orders/page']
    }
  },
  async created() {
    this.layout = this.$route.meta.layout
    await Promise.all([this.$store.dispatch('orders/fetch'), this.$store.dispatch('orders/fetchStatuses')])
    console.log(this.hasBlock)
  },
  methods: {
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('orders/fetch', {'filters[status][]': this.status})
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    async filterOrders(status) {
      this.dateRange = null
      this.status = status
      this.$store.commit('orders/reset')
      await this.$store.dispatch('orders/fetch', {'filters[status][]': status})
    },
    async filterOrdersDate(date) {
      this.status = null
      this.$store.commit('orders/reset')
      await this.$store.dispatch('orders/fetch', {
        'filters[routeDate][from]': date[0],
        'filters[routeDate][till]': date[1]
      })
      // filters[routeDate][from]
      // const d1 = this.filterParams.range[0]
      // const d2 = this.filterParams.range[1]
      // let d1local = null
      // let d2local = null
      // if (d1) d1local = d1.slice(8, 10) + '-' + d1.slice(5, 7) + '-' + d1.slice(0, 4)
      // if (d2) d2local = d2.slice(8, 10) + '-' + d2.slice(5, 7) + '-' + d2.slice(0, 4)

      // const mobile = document.documentElement.clientWidth <= 768
      // if (!date[0]) {
      //   date = null
      //   this.filterParams.dateRange = null
      // }
      // const filters = this.$store.getters['deliveries/filterParamsSend']
      // const filtersLocal = this.$store.getters['deliveries/filterParamsLocal']
      // if (mobile) {
      //   filters['filters[dateRange][from]'] = d1local
      //   filters['filters[dateRange][till]'] = d2local
      // } else {
      //   filters['filters[dateRange][from]'] = date ? date[0] : null
      //   filters['filters[dateRange][till]'] = date ? date[1] : null
      // }

      // filtersLocal.dateRange = date
      // this.$store.commit('deliveries/filterParamsLocal', filtersLocal)
      // this.$store.commit('deliveries/filterParamsSend', filters)
      // this.$store.commit('deliveries/reset')
      // this.$store.dispatch('deliveries/fetch')
    },
    openOrder(order) {
      this.$router.push('/enterprise/orders/' + order.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
